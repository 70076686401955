import React from 'react'
import event2 from './eventImg/event-2.webp'

const Event2 = () => {
  return (
    <>
    <div className='eventheading'>

<h1  className='text-center'>21st March 2025</h1>
<h3 className='text-center'>IEC Workshop on Advancements in Cooling Tower design and technology</h3>
</div>
   <div className='container' id='calanderevent'>
<img src={event2} alt=''/>
<button className='mb-4'><a href='https://ishraehq.in/Admin/CalenderEventMaster/Registration_new/eNxaS48VbJs=' target='_blank'>Register Now </a></button>
    </div>
    </>
  )
}

export default Event2