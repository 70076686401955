import React from 'react'
import event3 from './eventImg/event-3.webp'

const Event3 = () => {
  return (
    <>
    <div className='eventheading'>

<h1  className='text-center'>7th February 2025</h1>
<h3 className='text-center'>IEC Workshop on Kitchen Ventilation</h3>
</div>
   <div className='container' id='calanderevent'>
<img src={event3} alt=''/>
    </div>
    </>
  )
}

export default Event3