import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import About from "./components/about/About";
import AboutPresidents from "./components/about/AboutPresidents";
import Privacy from "./components/information/Privacy";
import Terms from "./components/information/Terms";
import RefundPolicy from "./components/information/RefundPolicy";
import ShippingPolicy from "./components/information/ShippingPolicy";
import Blog from "./components/blogs/Blog";
import Blog1 from "./components/blogs/Blog1";
import Blog2 from "./components/blogs/Blog2";

import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import CWC from "./components/about/CWC";
import Contact from "./components/contact/Contact";
import ScrollToTop from "./components/ScrollToTop";
import Event1 from "./components/home/event/Event1";
import Event2 from "./components/home/event/Event2";
import Event3 from "./components/home/event/Event3";
import NotFound from "./components/NotFound";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/event-1" element={<Event1 />} />
          <Route exact path="/event-2" element={<Event2 />} />
          <Route exact path="/event-3" element={<Event3 />} />
          
          <Route exact path="/about" element={<About />} />
          <Route exact path="/about-presidents" element={<AboutPresidents />} />
          <Route exact path="/about-cwc" element={<CWC />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog/The-Future-of-HVAC-Industry-in-India" element={<Blog1 />} />
          <Route exact path="/blog/Radiant-Cooling-The-Future-of-Energy-Efficient" element={<Blog2 />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          {/* 404 */}
          <Route exact path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
